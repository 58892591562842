import { string, func } from "prop-types";
import React from "react";
import { Button, Flex, Icon, Input, InputGroup, InputLeftAddon, InputRightElement } from "@chakra-ui/core";

function Form(props) {
  const { onSubmitEN, lang, langClick, word, onChangeEN, glowEffect, setWord, setGlowEffect } = props;

  return (
    <form name="searchbox" onSubmit={onSubmitEN}>
      <Flex justifyContent="flex-start" py={2}>
        <InputGroup w="100%" size="sm">
          <InputLeftAddon
            children={lang.toUpperCase()}
            borderRightRadius="0"
            borderBottomColor="#aaa"
            borderLeftColor="#aaa"
            borderTopColor="#aaa"
            onClick={langClick}
            cursor="pointer"
          />
          <Input
            type="text"
            name="word"
            autoFocus
            variant="outline"
            borderRightColor="#aaa"
            borderTopColor="#aaa"
            borderBottomColor="#aaa"
            borderRadius="0"
            borderBottomRightRadius="md"
            borderTopRightRadius="md"
            value={word}
            onChange={onChangeEN}
            mr={2}
            px={2}
            className={glowEffect}
          />
          <InputRightElement
            size="md"
            children={<Icon name="small-close" color="gray.400" />}
            mx={2}
            onClick={() => {
              setWord("");
              setGlowEffect("");
            }}
          />
        </InputGroup>
        <Button as="button" mt={0} type="submit" size="sm" variantColor="blue">
          <Icon name="search" mx={1} />
        </Button>
      </Flex>
    </form>
  );
}

Form.propTypes = {
  glowEffect: string.isRequired,
  lang: string.isRequired,
  langClick: func.isRequired,
  onChangeEN: func.isRequired,
  onSubmitEN: func.isRequired,
  setGlowEffect: func.isRequired,
  setWord: func.isRequired,
  word: string.isRequired,
};

export default Form;
